import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content, { HTMLContent } from '../components/Content'

export const TermsAndConditionsPageTemplate = ({ seo, content, contentComponent, }) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <SEO title={seo.title} description={seo.description} />
      <section className="section" style={{marginTop: 70}}>
        <div className="container">
          <PostContent content={content} className="markdown"/>
        </div>
      </section>
    </div>
  )
}

TermsAndConditionsPageTemplate.propTypes = {
  seo: PropTypes.object,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

const TermsAndConditionsPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <TermsAndConditionsPageTemplate
        seo={frontmatter.seo}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

TermsAndConditionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
}

export default TermsAndConditionsPage

export const pageQuery = graphql`
  query TermsAndConditionsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "terms-and-conditions-for-giveaway" } }) {
      html
      frontmatter {
        seo {
          title
          description
        }
      }
    }
  }
`

